@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Be Vietnam Pro", Arial, Helvetica, sans-serif, sans-serif;
}

@import url('https://fonts.googleapis.com/css?family=Be+Vietnam+Pro:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@media all {
  body {
    color: #212121;
    font-weight: 400;
    font-family: "Be Vietnam Pro", Helvetica, Arial, sans-serif;
    font-size: 62.5%;
    line-height: 1.15;
    background-color: #fff6f2!important;
  }
}

@media all {
  * {
    box-sizing: inherit;
    -webkit-box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    word-wrap: break-word;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }

  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0;
    background-color: #f2f2f2;
  }

  body {
    color: #212121;
  }

  body {
    font-weight: 400;
    font-family: "Be Vietnam Pro", Helvetica, Arial, sans-serif;
    font-size: 1.7rem;
    line-height: 1.75;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    font-size: 62.5%;
  }

  :root {
    --bloglo-white: #ffffff;
    --bloglo-primary: #0554f2;
    --bloglo-secondary: #232323;
    --bloglo-normal-radius: 0.3rem;
    --bloglo-transition-primary: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  :root {
    --bloglo-primary: #b8011f;
    --bloglo-primary_27: rgba(184, 1, 31, 0.27);
  }

  :root {
    --bloglo-secondary: #333333;
  }

  #bloglo-header-inner {
    border-style: solid;
    border-left: none;
    border-right: none;
    border-width: 0;
  }

  #bloglo-header-inner {
    background: #ffffff;
  }

  #bloglo-header-inner {
    border-color: rgba(39, 39, 39, 0.75);
  }

  *,
  :before,
  :after {
    box-sizing: inherit;
    -webkit-box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    word-wrap: break-word;
  }

  #colophon:after,
  #bloglo-preloader,
  .bloglo-icon,
  #bloglo-scroll-top .bloglo-scroll-icon,
  #bloglo-scroll-top:before,
  #bloglo-header-inner:after,
  .entry-media>a .entry-media-icon .entry-media-icon-wrapper,
  .entry-media>a .entry-media-icon::before,
  .entry-media>a .entry-media-icon svg,
  .page-header.bloglo-page-title-has-bg-img:after,
  .page-header:after,
  .post_format-post-format-quote .bloglo-blog-entry-content .entry-content .quote-link,
  .post_format-post-format-quote .bloglo-blog-entry-content .quote-post-bg,
  .post_format-post-format-quote .bloglo-blog-entry-content .quote-post-bg::after,
  .preloader-1,
  .bloglo-hover-slider .hover-slide-bg,
  .bloglo-hover-slider .hover-slider-backgrounds,
  .bloglo-hover-slider.slider-overlay-1 .hover-slider-backgrounds::after,
  .bloglo-pre-footer-cta-style-2 #bloglo-pre-footer::after,
  .bloglo-pre-footer-cta-style-2 #bloglo-pre-footer::before,
  .bloglo-spinner>div,
  .bloglo-tsp-header.bloglo-single-title-in-page-header #masthead::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #bloglo-header-inner:after {
    content: "";
  }

  :selection {
    background-color: var(--bloglo-primary);
    color: var(--bloglo-white);
  }

  #page ::selection {
    background-color: var(--bloglo-primary);
    color: var(--bloglo-white);
  }

  :-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
  }

  :-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  :-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  :-webkit-scrollbar-track {
    background: transparent;
  }

  .bloglo-container {
    padding: 0 5rem;
    margin: 0 auto;
    width: 100%;
  }

  .bloglo-container {
    max-width: 1420px;
  }

  #bloglo-header-inner>.bloglo-container {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #bloglo-header-inner>.bloglo-container {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  #comments .bypostauthor-badge,
  #comments .comment-actions,
  #comments .comment-header,
  #main>.bloglo-container,
  #page .gallery,
  #respond .comment-reply-title,
  #bloglo-scroll-top,
  #bloglo-scroll-top .bloglo-icon,
  #bloglo-copyright>.bloglo-container>.bloglo-flex-row>div,
  #bloglo-header-inner .bloglo-widget-wrapper,
  #bloglo-header-inner>.bloglo-container,
  #bloglo-topbar .bloglo-topbar-left,
  #bloglo-topbar .bloglo-topbar-right,
  #bloglo-topbar>.bloglo-container>.bloglo-flex-row>div,
  .author-box,
  .comment-form,
  .entry-media>a .entry-media-icon,
  .entry-media>a .entry-media-icon .bloglo-icon,
  .entry-media>a .entry-media-icon svg,
  .entry-meta .entry-meta-elements,
  .error404 #primary,
  .mejs-container .mejs-inner .mejs-controls .mejs-button,
  .mejs-container .mejs-inner .mejs-controls .mejs-time,
  .page-header .bloglo-page-header-wrapper,
  .page-header>.bloglo-container,
  .preloader-1,
  .bloglo-blog-horizontal .bloglo-article:not(.format-quote) .bloglo-blog-entry-wrapper,
  .bloglo-header-element,
  .bloglo-header-widgets,
  .bloglo-header-widgets .bloglo-cart,
  .bloglo-header-widgets .bloglo-header-widget,
  .bloglo-header-widgets .bloglo-search,
  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form button,
  .bloglo-hover-slider .hover-slide-item,
  .bloglo-input-supported .comment-form-cookies-consent,
  .bloglo-has-comments-toggle #bloglo-comments-toggle,
  .bloglo-header-layout-3 .bloglo-logo-container .bloglo-logo>.logo-inner,
  .bloglo-header-layout-3 .bloglo-logo-container>.bloglo-container,
  .bloglo-header-layout-3 .bloglo-nav-container>.bloglo-container,
  .bloglo-logo a,
  .single .entry-footer,
  .single .post-nav,
  .single .post-nav .nav-content {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bloglo-header-element {
    z-index: 99;
  }

  .bloglo-logo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .bloglo-header-element:not(.bloglo-mobile-nav) {
    position: relative;
  }

  .bloglo-header-element:first-child {
    margin-left: 0;
  }

  .bloglo-logo {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .bloglo-header-container .bloglo-logo {
    margin-right: 3.2rem;
  }

  .bloglo-mobile-nav {
    display: none;
    margin-left: 2.4rem;
  }

  .bloglo-mobile-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bloglo-nav {
    max-width: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .bloglo-nav.bloglo-header-element {
    font-size: 1.7rem;
  }

  .bloglo-header-container .bloglo-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .bloglo-header-element.bloglo-header-widgets {
    z-index: 5;
  }

  .bloglo-header-widgets {
    font-size: 1.7rem;
  }

  .bloglo-header-container .bloglo-header-widgets {
    margin-left: auto;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .bloglo-header-container .bloglo-header-widgets:last-child {
    margin-right: 0;
  }

  .bloglo-logo .logo-inner {
    margin-top: 25px;
    margin-right: 5px;
    margin-bottom: 25px;
  }

  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  [type="button"],
  button {
    -webkit-appearance: button;
  }

  .bloglo-hamburger {
    padding: 0 0;
    display: inline-block;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }

  .bloglo-hamburger {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bloglo-hamburger {
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: var(--bloglo-normal-radius);
  }

  .bloglo-hamburger {
    color: #30373e;
  }

  button.bloglo-hamburger {
    outline: none;
  }

  .bloglo-hamburger:hover {
    color: var(--bloglo-primary);
  }

  ul {
    box-sizing: border-box;
  }

  .bloglo-nav>ul,
  ul {
    margin: 0;
    padding: 0;
  }

  .bloglo-nav>ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .bloglo-nav>ul {
    width: 100%;
    height: 100%;
  }

  .bloglo-nav>ul {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  #bloglo-header-inner .bloglo-nav>ul {
    min-height: 9rem;
  }

  .bloglo-header-widgets .bloglo-header-widget {
    height: 100%;
    max-height: 9rem;
    position: relative;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .bloglo-header-element>.bloglo-header-widget:first-child {
    margin-left: 1.8rem;
  }

  .bloglo-header-widget:after {
    width: 0.1rem;
    height: 1.6rem;
    background-color: rgba(190, 190, 190, 0.3);
    display: inline-block;
    position: relative;
    right: -0.5rem;
    margin: -1.6rem 0 -1.6rem 1rem;
  }

  .bloglo-header-widget:after {
    background-color: #cccccc;
  }

  .bloglo-header-widget:last-of-type {
    padding-right: 0;
  }

  .bloglo-header-layout-1 .bloglo-header-widget:last-of-type::after,
  .bloglo-header-layout-2 .bloglo-header-widget:last-of-type::after {
    display: none;
  }

  a {
    -webkit-transition: var(--bloglo-transition-primary);
    transition: var(--bloglo-transition-primary);
  }

  a {
    background-color: transparent;
  }

  a {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  a {
    color: inherit;
    -webkit-text-decoration-skip: objects;
  }

  .bloglo-logo a,
  a {
    text-decoration: none;
  }

  .bloglo-logo a {
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }

  .bloglo-logo a {
    color: #30373e;
  }

  .hamburger-box {
    width: 2.5rem;
    height: 1.4rem;
    display: inline-block;
    position: relative;
  }

  .bloglo-nav>ul>li {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .bloglo-nav>ul>li {
    position: relative;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 1.6rem;
  }

  .bloglo-nav>ul>li:first-child,
  .bloglo-nav>ul>li:first-of-type {
    margin-left: 0 !important;
  }

  #bloglo-header-inner .bloglo-nav>ul>li {
    margin-left: 2.8rem;
  }

  .bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li {
    margin-left: 0.1rem;
  }

  .bloglo-nav>ul>li:last-child {
    margin-right: 0 !important;
  }

  #bloglo-header-inner .bloglo-widget-wrapper {
    position: relative;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  img {
    border-style: none;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  .bloglo-logo img {
    max-height: 45px;
  }

  .bloglo-logo a img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.1rem;
  }

  .hamburger-inner {
    width: 2.8rem;
    height: 2px;
    background-color: #232323;
    border-radius: var(--bloglo-normal-radius);
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
  }

  .hamburger--spin .hamburger-inner {
    -webkit-transition-duration: 0.22s;
    transition-duration: 0.22s;
    -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .bloglo-hamburger .hamburger-inner {
    background-color: currentColor;
  }

  .hamburger-inner,
  .hamburger-inner:before,
  .hamburger-inner:after {
    width: 2.8rem;
    height: 2px;
    background-color: #232323;
    border-radius: var(--bloglo-normal-radius);
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
  }

  .hamburger-inner:before,
  .hamburger-inner:after {
    content: "";
    display: block;
  }

  .hamburger-inner:before {
    top: -5px;
  }

  .hamburger--spin .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }

  .bloglo-hamburger .hamburger-inner,
  .bloglo-hamburger .hamburger-inner::before,
  .bloglo-hamburger .hamburger-inner::after {
    background-color: currentColor;
  }

  body:not(.is-mobile-menu-active) .bloglo-hamburger .hamburger-box .hamburger-inner::before {
    width: 1.5rem;
  }

  .hamburger-inner:after {
    bottom: -5px;
  }

  .hamburger--spin .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .bloglo-hamburger .hamburger-inner,
  .bloglo-hamburger .hamburger-inner::before,
  .bloglo-hamburger .hamburger-inner::after {
    background-color: currentColor;
  }

  .menu-item>a {
    border-radius: 0.8rem;
  }

  .bloglo-nav>ul>li>a,
  a {
    text-decoration: none;
  }

  .bloglo-nav>ul>li>a {
    display: flex;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
  }

  #bloglo-header-inner .bloglo-nav>ul>li>a {
    font-size: inherit;
    font-weight: 500;
  }

  .bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li>a {
    padding: 0.6rem 1.4rem;
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li>a {
    padding: 0.4rem 1.4rem 0.4rem;
  }

  #bloglo-header-inner .bloglo-nav>ul>li>a:hover {
    color: var(--bloglo-primary);
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li>a:hover {
    color: #fff !important;
    box-shadow: 0 10px 18px 0 var(--bloglo-primary_27);
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li>a:hover {
    background-color: var(--bloglo-primary);
    color: #fff;
  }

  .content-area a:not(.bloglo-btn, .wp-block-button__link, .page-numbers, [rel^="category"]),
  #secondary .hester-core-custom-list-widget .bloglo-entry a:not(.bloglo-btn),
  #secondary a:not(.bloglo-btn):hover,
  .using-keyboard #secondary a:not(.bloglo-btn):focus,
  .bloglo-header-widgets .bloglo-header-widget.bloglo-active .bloglo-icon.bloglo-search,
  .bloglo-logo .site-title a:hover,
  .using-keyboard .site-title a:focus,
  #bloglo-header-inner .bloglo-nav>ul>li>a:hover,
  .using-keyboard #bloglo-header-inner .bloglo-nav>ul>li>a:focus,
  #bloglo-header-inner .bloglo-nav>ul>li.menu-item-has-children:hover>a,
  .using-keyboard #bloglo-header-inner .bloglo-nav>ul>li.menu-item-has-children:focus>a,
  #bloglo-header-inner .bloglo-nav>ul>li.current-menu-item>a,
  #bloglo-header-inner .bloglo-nav>ul>li.current-menu-ancestor>a,
  #bloglo-header-inner .bloglo-nav>ul>li.page_item_has_children:hover>a,
  #bloglo-header-inner .bloglo-nav>ul>li.current_page_item>a,
  #bloglo-header-inner .bloglo-nav>ul>li.current_page_ancestor>a,
  #bloglo-topbar .bloglo-nav>ul>li>a:hover,
  .using-keyboard #bloglo-topbar .bloglo-nav>ul>li>a:focus,
  #bloglo-topbar .bloglo-nav>ul>li.menu-item-has-children:hover>a,
  #bloglo-topbar .bloglo-nav>ul>li.current-menu-item>a,
  #bloglo-topbar .bloglo-nav>ul>li.current-menu-ancestor>a,
  .bloglo-social-nav>ul>li>a .bloglo-icon.bottom-icon,
  .bloglo-header-widgets a:not(.bloglo-btn):hover,
  .using-keyboard .bloglo-header-widgets a:not(.bloglo-btn):focus,
  #bloglo-header-inner .bloglo-header-widgets .bloglo-active,
  .widget .cat-item.current-cat>a,
  .widget ul li.current_page_item>a,
  #cancel-comment-reply-link:hover,
  .using-keyboard #cancel-comment-reply-link:focus,
  .comment-form .required,
  #main .entry-meta a:hover,
  .using-keyboard #main .entry-meta a:focus,
  #main .author-box-title a:hover,
  .using-keyboard #main .author-box-title a:focus,
  .bloglo-btn.btn-outline,
  code,
  kbd,
  var,
  samp,
  tt,
  .is-mobile-menu-active .bloglo-hamburger,
  .bloglo-hamburger:hover,
  .single #main .post-nav a:hover,
  .using-keyboard .single #main .post-nav a:focus,
  #bloglo-topbar .bloglo-topbar-widget__text .bloglo-icon {
    color: var(--bloglo-primary);
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current-menu-item>a,
  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current_page_item>a {
    color: #fff !important;
    box-shadow: 0 10px 18px 0 var(--bloglo-primary_27);
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current-menu-item>a,
  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current_page_item>a {
    background-color: var(--bloglo-primary);
    color: #fff;
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current-menu-item>a,
  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current_page_item>a,
  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li>a:hover {
    color: #fff !important;
    box-shadow: 0 10px 18px 0 var(--bloglo-primary_27);
  }

  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li>a:hover,
  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current-menu-item>a,
  body.bloglo-menu-animation-squarebox:not(.bloglo-is-mobile) #bloglo-header-inner .bloglo-nav>ul>li.current_page_item>a {
    background-color: var(--bloglo-primary);
    color: #fff;
  }

  .bloglo-social-nav {
    -ms-flex-align: center;
    align-items: center;
  }

  .bloglo-social-nav {
    height: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .bloglo-social-nav>ul,
  ul {
    margin: 0;
    padding: 0;
  }

  .bloglo-social-nav>ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .bloglo-social-nav>ul {
    height: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .bloglo-social-nav>ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }

  .bloglo-header-widgets .bloglo-search {
    position: relative;
  }

  .bloglo-header-widgets a:not(.bloglo-btn),
  a {
    text-decoration: none;
  }

  .bloglo-header-widgets a:not(.bloglo-btn) {
    color: inherit;
  }

  .bloglo-header-widgets a:not(.bloglo-btn) {
    color: #30373e;
  }

  .bloglo-search-simple {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(0.5rem);
    -ms-transform: translateY(0.5rem);
    transform: translateY(0.5rem);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .bloglo-header-widgets .bloglo-search-simple {
    position: absolute;
    right: -1.5rem;
    top: 100%;
    z-index: 9;
    border-top-width: 0.2rem;
    border-top-style: solid;
  }

  .bloglo-header-widgets .dropdown-item {
    width: 28rem;
    background-color: var(--bloglo-white);
    -webkit-box-shadow: 0 1rem 3.5rem -0.3rem rgba(0, 0, 0, 0.12);
    box-shadow: 0 1rem 3.5rem -0.3rem rgba(0, 0, 0, 0.12);
    border-radius: 0 0 0.3rem 0.3rem;
    border: 0.1rem solid rgba(190, 190, 190, 0.3);
    border-top-width: 0.2rem;
  }

  .bloglo-header-widgets .dropdown-item {
    border-top-color: var(--bloglo-primary);
  }

  .bloglo-header-widgets .bloglo-search-simple::after {
    bottom: 100%;
    right: 1.6rem;
    content: "";
    width: 0;
    height: 0;
    margin-left: -0.7rem;
    border: 0.7rem solid transparent;
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }

  #masthead .bloglo-header-widgets .dropdown-item::after,
  .bloglo-nav>ul .sub-menu::after,
  textarea:focus,
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="tel"]:focus,
  input[type="url"]:focus,
  input[type="date"]:focus {
    border-bottom-color: var(--bloglo-primary);
    outline: none !important;
  }

  .bloglo-social-nav>ul>li {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100%;
  }

  .bloglo-icon {
    display: inline-block;
    fill: currentColor;
    width: auto;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  .bloglo-header-widgets .bloglo-header-widget:not(.bloglo-header-widget__button, .bloglo-header-widget__socials) .bloglo-icon {
    height: 2.057rem;
    width: auto;
  }

  .bloglo-social-nav>ul>li>a {
    display: block;
    margin-right: 1.6rem;
  }

  .bloglo-header-widgets a:not(.bloglo-btn),
  .bloglo-social-nav>ul>li>a,
  a {
    text-decoration: none;
  }

  .bloglo-social-nav.rounded>ul>li>a {
    margin-right: 0;
  }

  .bloglo-social-nav>ul>li>a::before {
    display: none !important;
  }

  .bloglo-social-nav>ul>li:last-child>a {
    margin-right: 0;
  }

  .bloglo-social-nav.rounded>ul>li>a,
  .bloglo-social-nav.rounded>ul>li:last-child>a {
    margin-right: 0;
  }

  label {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  label {
    display: inline-block;
    font-weight: 600;
  }

  .bloglo-form-label {
    margin: 0;
    padding: 0;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-form-label {
    display: block;
    width: 100%;
  }

  [type="submit"],
  button {
    -webkit-appearance: button;
  }

  button.bloglo-animate-arrow {
    margin: 0;
    padding: 0;
  }

  button.bloglo-animate-arrow {
    -webkit-appearance: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    outline: none;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form button {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.8rem;
    border-radius: 10rem;
    margin: auto 0;
    cursor: pointer;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form button:not(.bloglo-search-close) {
    right: 5.6rem;
    color: var(--bloglo-white);
    background-color: var(--bloglo-primary);
  }

  .bloglo-animate-arrow:hover {
    cursor: pointer;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form .bloglo-search-close {
    right: 1.6rem;
  }

  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }

  .screen-reader-text {
    border: 0;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 0.1rem;
    word-wrap: normal !important;
    word-break: normal;
  }

  .bloglo-social-nav>ul>li>a span {
    position: relative;
  }

  .bloglo-social-nav>ul>li>a>span:not(.screen-reader-text) {
    display: flex;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    color: inherit;
    font-weight: 400;
    overflow-y: hidden;
    -webkit-transition: var(--bloglo-transition-primary);
    transition: var(--bloglo-transition-primary);
  }

  .bloglo-social-nav.rounded>ul>li>a>span:not(.screen-reader-text) {
    margin-right: 0.8rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    padding: 0;
  }

  .bloglo-social-nav.rounded>ul>li>a>span:not(.screen-reader-text) {
    width: 3.6rem;
    height: 3.6rem;
  }

  input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  input {
    overflow: visible;
  }

  input {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -0.2rem;
  }

  input[type="search"] {
    -webkit-transition: var(--bloglo-transition-primary);
    transition: var(--bloglo-transition-primary);
  }

  input[type="search"] {
    font-size: inherit;
    font-weight: 400;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    border: 0.2rem solid rgba(190, 190, 190, 0.3);
    background-color: var(--bloglo-white);
    padding: 0.9rem 1.6rem;
    color: #66717f;
    width: 100%;
    border-radius: var(--bloglo-normal-radius);
    line-height: 1 !important;
    min-height: 5rem;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form input,
  input[type="search"] {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form input {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form input {
    font-size: 1.5rem;
    color: var(--bloglo-secondary);
    display: block;
    width: 100%;
    padding: 1.6rem 4.5rem 1.6rem 2rem;
  }

  .bloglo-animate-arrow svg {
    vertical-align: middle;
  }

  .bloglo-animate-arrow.right-arrow svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form button:not(.bloglo-search-close) svg {
    fill: currentColor;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form .bloglo-search-close svg {
    display: inline-block;
    vertical-align: middle;
  }

  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form .bloglo-search-close svg {
    -webkit-transition: var(--bloglo-transition-primary);
    transition: var(--bloglo-transition-primary);
  }

  .bloglo-social-nav>ul>li>a .bloglo-icon {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .bloglo-social-nav>ul>li>a .bloglo-icon {
    -js-display: inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: -webkit-transform 0.22s;
    transition: -webkit-transform 0.22s;
    transition: transform 0.22s;
    transition: transform 0.22s, -webkit-transform 0.22s;
    opacity: 1;
    will-change: transform;
  }

  .bloglo-social-nav>ul>li>a .bloglo-icon {
    height: 1.6rem;
  }

  .bloglo-social-nav.rounded>ul>li>a .bloglo-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .bloglo-social-nav>ul>li>a .bloglo-icon.bottom-icon {
    position: absolute;
    -webkit-transform: translate3d(0, 225%, 0);
    transform: translate3d(0, 225%, 0);
  }

  .bloglo-social-nav.rounded>ul>li>a {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative;
    /* Ensure positioning context for absolute elements */
  }

  .bloglo-social-nav.rounded>ul>li>a .bloglo-icon {
    display: block;
    margin: 0 auto;
    position: relative;
    /* Reset position for non-bottom icons */
    transform: none;
    /* Reset transform for non-bottom icons */
  }

  .bloglo-social-nav>ul>li>a .bloglo-icon.bottom-icon {
    position: absolute;
    transform: translate3d(0, 225%, 0);
  }

  #bloglo-topbar {
    border-style: solid;
    border-left: none;
    border-right: none;
    border-width: 0;
    font-size: 1.3rem;
    z-index: 6;
  }

  #bloglo-topbar {
    position: relative;
  }

  #bloglo-topbar {
    background: #b8011f;
  }

  #bloglo-topbar {
    border-color: rgba(0, 0, 0, 0.085);
    border-style: solid;
    border-bottom-width: 1px;
  }

  #bloglo-topbar {
    color: #ffffff;
  }

  *,
  :before,
  :after {
    box-sizing: inherit;
    -webkit-box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
    word-wrap: break-word;
  }

  :selection {
    background-color: var(--bloglo-primary);
    color: var(--bloglo-white);
  }

  #page ::selection {
    background-color: var(--bloglo-primary);
    color: var(--bloglo-white);
  }

  :-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
  }

  :-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  :-webkit-scrollbar-track {
    background: transparent;
  }

  :-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  .bloglo-container {
    padding: 0 5rem;
    margin: 0 auto;
    width: 100%;
  }

  .bloglo-container {
    max-width: 1420px;
  }

  #bloglo-topbar>.bloglo-container {
    position: relative;
  }

  #bloglo-topbar,
  #bloglo-topbar>.bloglo-container {
    position: relative;
  }

  .bloglo-flex-row {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    --bloglo-gutter-x: 2.4rem;
    --bloglo-gutter-y: 0;
    margin-top: calc(var(--bloglo-gutter-y) * -1);
    margin-right: calc(var(--bloglo-gutter-x) / -2);
    margin-left: calc(var(--bloglo-gutter-x) / -2);
  }

  #bloglo-topbar>.bloglo-container>.bloglo-flex-row {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 5rem;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .bloglo-flex-row div[class^="col-"],
  .bloglo-flex-row div[class*="col-"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    padding-right: calc(var(--bloglo-gutter-x) / 2);
    padding-left: calc(var(--bloglo-gutter-x) / 2);
    margin-top: var(--bloglo-gutter-y);
  }
}

@media only screen and (min-width: 600px) {
  .bloglo-flex-row .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }
}

@media only screen and (min-width: 783px) {
  .bloglo-flex-row .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    max-width: 100%;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
}

@media all {
  .bloglo-flex-row>div:not(.col-xs-12) {
    width: 100%;
  }
}

@media only screen and (min-width: 783px) {
  .bloglo-flex-row .col-md.flex-basis-auto {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media all {
  #bloglo-topbar>.bloglo-container>.bloglo-flex-row>div {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #bloglo-topbar>.bloglo-container>.bloglo-flex-row>div {
    width: auto;
    max-width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .bloglo-flex-row .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
    margin-left: auto;
  }
}

@media all {

  #comments .bypostauthor-badge,
  #comments .comment-actions,
  #comments .comment-header,
  #main>.bloglo-container,
  #page .gallery,
  #respond .comment-reply-title,
  #bloglo-scroll-top,
  #bloglo-scroll-top .bloglo-icon,
  #bloglo-copyright>.bloglo-container>.bloglo-flex-row>div,
  #bloglo-header-inner .bloglo-widget-wrapper,
  #bloglo-header-inner>.bloglo-container,
  #bloglo-topbar .bloglo-topbar-left,
  #bloglo-topbar .bloglo-topbar-right,
  #bloglo-topbar>.bloglo-container>.bloglo-flex-row>div,
  .author-box,
  .comment-form,
  .entry-media>a .entry-media-icon,
  .entry-media>a .entry-media-icon .bloglo-icon,
  .entry-media>a .entry-media-icon svg,
  .entry-meta .entry-meta-elements,
  .error404 #primary,
  .mejs-container .mejs-inner .mejs-controls .mejs-button,
  .mejs-container .mejs-inner .mejs-controls .mejs-time,
  .page-header .bloglo-page-header-wrapper,
  .page-header>.bloglo-container,
  .preloader-1,
  .bloglo-blog-horizontal .bloglo-article:not(.format-quote) .bloglo-blog-entry-wrapper,
  .bloglo-header-element,
  .bloglo-header-widgets,
  .bloglo-header-widgets .bloglo-cart,
  .bloglo-header-widgets .bloglo-header-widget,
  .bloglo-header-widgets .bloglo-search,
  .bloglo-header-widgets .bloglo-search-simple .bloglo-search-form button,
  .bloglo-hover-slider .hover-slide-item,
  .bloglo-input-supported .comment-form-cookies-consent,
  .bloglo-has-comments-toggle #bloglo-comments-toggle,
  .bloglo-header-layout-3 .bloglo-logo-container .bloglo-logo>.logo-inner,
  .bloglo-header-layout-3 .bloglo-logo-container>.bloglo-container,
  .bloglo-header-layout-3 .bloglo-nav-container>.bloglo-container,
  .bloglo-logo a,
  .single .entry-footer,
  .single .post-nav,
  .single .post-nav .nav-content {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #bloglo-topbar .bloglo-topbar-widget {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 5;
  }

  #bloglo-topbar .bloglo-topbar-widget:first-child {
    padding-left: 0;
    border-left: 0;
  }

  #bloglo-topbar .bloglo-topbar-widget:last-child {
    padding-right: 0;
    border-right: 0 !important;
  }

  .bloglo-topbar-widget:after {
    background-color: rgba(190, 190, 190, 0.3);
  }

  .bloglo-topbar-widget:after {
    background-color: #cccccc;
  }

  #bloglo-topbar .bloglo-topbar-widget:last-child::after {
    display: none !important;
  }

  .far {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .fa-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -.0667em;
  }

  .far {
    font-weight: 400;
  }

  .far {
    font-family: "Font Awesome 5 Free";
  }

  .bloglo-icon {
    display: inline-block;
    fill: currentColor;
    width: auto;
  }

  #bloglo-topbar .bloglo-topbar-widget__text .bloglo-icon {
    margin: 0 0.7rem 0 0;
    position: relative;
    vertical-align: middle;
  }

  #bloglo-topbar .bloglo-topbar-widget__text .bloglo-icon {
    color: var(--bloglo-primary);
  }

  #bloglo-topbar .bloglo-topbar-widget__text .bloglo-icon {
    color: #ffffff;
  }

  .fa-gift:before {
    content: "\f06b";
  }

  strong {
    font-weight: 600;
  }

  .fa-location-arrow:before {
    content: "\f124";
  }

  a {
    text-decoration: none;
  }

  a {
    background-color: transparent;
  }

  a {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  a {
    color: inherit;
    -webkit-text-decoration-skip: objects;
  }

  .bloglo-topbar-widget__text a {
    color: #ffffff;
  }

  #bloglo-topbar a,
  a {
    -webkit-transition: var(--bloglo-transition-primary);
    transition: var(--bloglo-transition-primary);
  }

  #bloglo-topbar .bloglo-topbar-widget__text a {
    display: inline-block;
    position: relative;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
  }

  #bloglo-copyright .bloglo-copyright-widget__text a::before,
  #bloglo-topbar .bloglo-topbar-widget__text a::before,
  .bloglo-breadcrumbs a::before,
  .bloglo-menu-animation-underline #bloglo-copyright .bloglo-nav>ul>li>a>span::before,
  .bloglo-menu-animation-underline #bloglo-header-inner .bloglo-nav>ul>li>a>span::before,
  .widget.widget_icon_list ul li a::before,
  .widget.widget_meta ul li a::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background: currentColor;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1) translateZ(0.1rem);
    transform: scale(0, 1) translateZ(0.1rem);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: scale;
  }

  #bloglo-topbar .bloglo-topbar-widget__text a:hover {
    color: #ffffff;
  }

  .using-keyboard #bloglo-copyright .bloglo-copyright-widget__text a:focus::before,
  .using-keyboard #bloglo-topbar .bloglo-topbar-widget__text a:focus::before,
  .using-keyboard .bloglo-breadcrumbs a:focus::before,
  .using-keyboard.bloglo-menu-animation-underline #bloglo-copyright .bloglo-nav>ul>li>a:focus>span::before,
  .using-keyboard.bloglo-menu-animation-underline #bloglo-header-inner .bloglo-nav>ul>li>a:focus>span::before,
  .using-keyboard .widget.widget_icon_list ul li a:focus::before,
  .using-keyboard .widget.widget_meta ul li a:focus::before,
  #bloglo-copyright .bloglo-copyright-widget__text a:hover::before,
  #bloglo-topbar .bloglo-topbar-widget__text a:hover::before,
  .bloglo-breadcrumbs a:hover::before,
  .bloglo-menu-animation-underline #bloglo-copyright .bloglo-nav>ul>li>a>span:hover::before,
  .bloglo-menu-animation-underline #bloglo-header-inner .bloglo-nav>ul>li>a>span:hover::before,
  .widget.widget_icon_list ul li a:hover::before,
  .widget.widget_meta ul li a:hover::before {
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1) translateZ(0.1rem);
    transform: scale(1, 1) translateZ(0.1rem);
  }

  
}

.block-link {
  color: white!important;
  box-shadow: 0 10px 18px 0 var(--bloglo-primary_27);
  padding: 0.4rem 1.4rem 0.4rem;
  background-color: var(--bloglo-primary);
  border-radius: 0.8rem;
}


.hero-background-image {
  background-image: url("./images/gift-background.jpg");
  background-size: cover;
  background-position: center;
  min-height: 50vh;
}

.hero-header {
  font-weight: 500;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

p { 
  font-weight: 400;
}

.display-3 {
  font-weight: 500;
}

.footer {
  background-color: white;
}

.footer a {
  color: var(--bloglo-primary);
}

.giftko {

}

.overlay {
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.giftko-form {
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {
  .bloglo-mobile-nav {
    display: none;
  }
}